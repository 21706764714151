@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-Regular.ttf");
}

.space {
  margin: 0 5px 25px 0;
}

.layer {
  height: 140px;
}

.about {
  background-color: #edd2c8;
}

.aboutContainer {
  display: flex;
  margin: 0 200px;
  flex-wrap: wrap;
  font-size: 30px;
  justify-content: center;
  text-align: center;
  z-index: 0 !important;
  font-family: "Work Sans" !important;

  @media screen and (max-width: 1040px) {
    margin: 0 25px;
  }
}

.word {
  text-decoration: none !important;
  color: transparent !important;
  -webkit-text-stroke: 1px #4d4c4d;
}

.word:hover {
  cursor: pointer;
}

.red {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgb(254, 40, 41) !important;
  }
}

.purple {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgb(205, 48, 122) !important;
  }
}

.green {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgba(28, 151, 87, 1) !important;
  }
}

.gray-blue {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgb(96, 135, 164) !important;
  }
}

.lime {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgb(192, 255, 118) !important;
  }
}

.orange {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;

  &:hover {
    color: rgb(224, 150, 30) !important;
  }
}

.twitter-blue {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;
  text-decoration: none;

  &:hover {
    color: rgb(27, 161, 242) !important;
  }
}

.linkedin-blue {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;
  text-decoration: none;

  &:hover {
    color: rgb(1, 119, 181) !important;
  }
}

.white {
  color: transparent;
  transition: color 750ms cubic-bezier(0.19, 1, 0.22, 1) 0ms !important;
  text-decoration: none;

  &:hover {
    color: white !important;
  }
}
